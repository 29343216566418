.btn-inline
  padding: 6px 10px 6px 13px;
  i
    font-size: 20px

.row.vcent
  line-height: 39px;

.btn-group .btn:first-child {
  margin-right: 0;
}

.modal-footer .btn+.btn
  margin-bottom 5px

.input-group-btn .btn {
  padding: 11px 11px 9px 12px;
}

.fab
  padding: 0
  width 50px
  height 50px
  position fixed
  -webkit-border-radius: 50%
  -moz-border-radius: 50%
  border-radius:50%
  bottom 40px
  right 40px
  line-height: 56px
  -webkit-transition: all .2s
  -moz-transition: all .2s
  -ms-transition: all .2s
  -o-transition: all .2s
  transition: all .2s
  background-color: #c0392b
  border-color: #c0392b
  color: #FFF
  -webkit-box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.75);
  box-shadow: 1px 1px 5px 0 rgba(0,0,0,0.75);
  i
    font-size: 27px
    margin: 0
  &:hover
    background-color: #c0392b
    color: #70221a
    -webkit-box-shadow: 4px 4px 5px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 4px 5px 0 rgba(0,0,0,0.75);
    box-shadow: 4px 4px 5px 0 rgba(0,0,0,0.75);
