emailred = #c0392b

.spoof-bar {
  padding: 10px;
  text-align: center;
  background: #333333;
  color: white;
  & + header .navbar-fixed-top {
    top: initial;
  }
}

@media (max-width: 991px)
  .navbar
    background-color emailred

.side-panel
  width: 90%
  position: absolute
  right: -100%
  -webkit-transition: all .4s
  -moz-transition: all .4s
  -ms-transition: all .4s
  -o-transition: all .4s
  transition: all .4s
  background-color: white
  z-index: 1029
  height: 100%
  -webkit-box-shadow: -5px 0 5px 0 rgba(0,0,0,0.45);
  -moz-box-shadow: -5px 0 5px 0 rgba(0,0,0,0.45);
  box-shadow: -5px 0 5px 0 rgba(0,0,0,0.45);
  display: none
  overflow: hidden
  &.display
    display block
  &.active
    right: 0;

.dismiss-panel
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  display: none
  background-color: black
  z-index: 1028
  opacity: .5

.strong
  font-weight: 600

#bee-plugin
  height calc(100% - 5px)
  top 0
  bottom 0
  z-index: 1031

// Loading Spinner
.spinner {
  position: fixed
  left: calc(50% - 100px)
  top: calc(50% - 14px)
  text-align: center;
  background-color: rgba(255,255,255, .8)
  padding: 9px
  -webkit-border-radius: 4px
  -moz-border-radius: 4px
  border-radius: 4px

}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: emailred;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

hr
  border-top: 1px solid #9E9E9E

// Positional Helpers
.pos-fixed
  position: fixed;
.pos-relative
  position: relative;
.pos-absolute
  position: absolute;