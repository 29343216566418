.toasts
  position: fixed;
  top: 130px;
  right: 40px;
  z-index 100000 // always on top
  .toast
    -webkit-transition: all 0.3s
    -moz-transition: all 0.3s
    -ms-transition: all 0.3s
    -o-transition: all 0.3s
    transition: all 0.3s
    padding: 15px;
    position: relative
    top -200px
    border-radius: 4px;
    color: #FFFFFF;
    max-width: 250px
    p
      margin-right: 15px
      max-width:200
      word-wrap: break-word;
    &.active
      z-index: 10000
      margin-bottom 5px
      top 0
    &.err
      background-color: #c0392b;
    &.success
      background-color: #14E82E
    &.warn
      background-color #ffa100
    &.dismissing
      right: -120%
    i.dismiss
      position: absolute
      top: calc(50% - 10px);
      right: 8px
      font-size: 18px
      color: rgba(255, 255, 255, 0.5)